import React, { Fragment, Component} from 'react';
import axios from 'axios';
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons'

export class Inscripciones extends Component {
    constructor (props) {
     super (props)
       
     this.state = {
            inputBuscar: '',
            results: []
        }
    }
    handleChange = (e) =>{
        this.setState({inputBuscar: e.target.value})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state.inputBuscar)
    }
    
    handleResults(results){
        this.setState({ results })
    }

    getRecentInscripciones(){
        axios.get(Constants.ApiURL+"/inscripciones")
        .then(
            response => {
                this.setState({results : response.data.results })
                console.log(this.state)                 
            }
        )
        .catch(error => {
            console.log("login error", error);
        });
    }

    componentDidMount(){
        this.getRecentInscripciones()
    }

    //renderResults = (e) =>{
    renderResults() {
        console.log(this.state.results)
        return this.state.results.map(function (resultado) {
            return (                
                <tr>
                    <th scope="col"><img src="//via.placeholder.com/30/" class="rounded-circle" alt={resultado.name} /> {resultado.name}</th>
                    <th scope="col">{resultado.email}</th>
                    <th scope="col">
                        <Link to={Constants.Link_inscripciones__edit+resultado.cedula}><FontAwesomeIcon icon={faPencilAlt} size="1x"/></Link>
                        <Link to={Constants.Link_inscripciones__detail+resultado.cedula}><FontAwesomeIcon icon={faEye} size="1x"/></Link></th>
                </tr>
            );
        })        
    }

    render () {
        
        return (
            <Fragment>
                <h4>Inscripciones en curso</h4> 
                <Link to={Constants.Link_inscripciones__add}>Inscribir Alumno</Link>
                <form onSubmit={this.renderResults}>
                    <div className="">
                        Buscar
                        <input 
                        className="form-control"
                        type="text"
                        placeholder="Buscar nombre"
                        disabled
                        onChange={this.handleChange} />
                        <button type="submit" disabled>Buscar</button>
                    </div>
                </form>

                <table className="table table-striped">
                    <thead className="bg-primary">
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Progreso</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {  this.state.results.length === 0
                            ?<tr>No hay Inscripciones en curso</tr>
                            :this.renderResults()
                        }     
                    </tbody>
                </table>                
            </Fragment>
        )
    }
}
export default Inscripciones;