import React from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants'

export const LinksProfesor = (props) => {

    return (
        <ul>
            <li><Link to="/panel/">Dashboard</Link></li>
            <li><Link to="/panel/materias">Materias</Link></li>
            <li><Link to="/panel/mensajes">Mensajes</Link></li>
            <li><Link to="/panel/perfil">Mi Perfil</Link></li>
        </ul>
    )
} 
