
export const ApiURL = "https://test.disegnovalencia.com/html/api";
//export const ApiURL = "https://prueba.disegnovalencia.com/api/pruebaderegistro";


export const Link_inscripciones__add    = "/panel/inscripcion/add/"
export const Link_inscripciones__edit   = "/panel/inscripcion/edit/"
export const Link_inscripciones__detail = "/panel/inscripcion/"
export const Link_inscripciones__main   = "/panel/inscripciones/"

export const TOKEN_SESSION     =   "idv_token"
export const TOKEN_SESSION_USER =   "idv_user"
export const TOKEN_SESSION_ROLE =   "idv_role"