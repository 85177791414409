import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Constants from '../../../Constants/Constants'
import { history } from '../../../Helpers/History'

class SignOut extends Component {

    componentDidMount() {        
        this.signMeOut()
    }

    signMeOut () {
        localStorage.removeItem(Constants.TOKEN_SESSION);
        localStorage.removeItem(Constants.TOKEN_SESSION_USER);
        localStorage.removeItem(Constants.TOKEN_SESSION_ROLE);
        this.props.history.push("/");
    }
    render () {
        //console.log(state)
        return (
            <>
            Logut
            </>
        )
    }
}


export default connect(null, null)(SignOut)