import React, { Component } from 'react';

/*function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
}*/

class AreaTrivia extends Component {
    
    /*trivia = [
        {id: 1, question: 'sss', options: "s", answer: "1"}
    ]*/

    //Referencia para desarrollar
    //https://dev.to/abdulbasit313/building-a-scalable-random-quote-app-in-react-461e

    render () {
       
        return(
            <div>
                <div className="d-block col-12">pregunta trivia</div>
                <div className="d-flex col-12">
                    <input className="btn btn-outline-primary" type="button" value="Opcion 1" /><br/>
                    <input className="btn btn-outline-primary" type="button" value="Opcion 2" /><br/>
                    <input className="btn btn-outline-primary" type="button" value="Opcion 3" /><br/>
                </div>  
            </div>
        )
    }
}

export default AreaTrivia;
