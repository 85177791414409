import React, { Component } from 'react';
import AreaLogin from './AreaLogin'
import AreaTrivia from './AreaTrivia'
import { connect } from 'react-redux';
import * as actions from '../../Actions/UserActions'
import { history } from '../../Helpers/History'

class LoginPage extends Component {
    
    constructor (props) {
        super(props);
        this.handleSuccessfulAuth   = this.handleSuccessfulAuth.bind(this);
        this.handleLogoutClick      = this.handleLogoutClick.bind(this);        
    }


    componentDidMount() {
        //Check for previous Session
        const isPrevLogged = this.props.getProfileFetch()
        console.log(isPrevLogged)
        //check if valid and move on
        if (isPrevLogged === true) {
            history.push("/panel") 
            console.log("Aajam")
        } else {
            console.log("hella no")
            //Do nothing
        }

        /*this.props.getProfileFetch().then(
           (response) => {
            
            if(response.success == true) {
                history.push("/panel") 
            } else{
                console.log("revisar")
            }


           },
           (err) => {
             //this.setState({errors: err.data.error, isProcessing: false})
           }
         )  */      
         
     }

    handleSuccessfulAuth(data){
        this.props.handleLogin(data);
        this.props.history.push("/dashboard");
    }

    handleLogoutClick(){
        //axios logout
    }

    render(){
        const { match } = this.props
        return(
            <div className="container-fluid">
		        <div className="row vh-100">
                    <div className="area-login col-12 col-sm-5 h-100 bg-secondary">
                        <div className="row justify-content-md-center">
                           
                           <AreaLogin 
                            handleSuccessfulAuth = {this.handleSuccessfulAuth}
                            //loggedStatus      = {this.props.loggedStatus} 
                            />
                        </div>
                    </div>
                    <div className="area-trivia col-12 col-sm-7 h-100 d-none d-sm-block d-sm-none d-md-block">
                        <div className="row  align-items-center justify-content-center h-100">
                            <AreaTrivia />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { 
      userReducer: state.userReducer,
    }
}
const mapDispatchToProps = dispatch => ({
    getProfileFetch: () => dispatch(actions.getProfileFetch())
  })
export default connect(
    mapStateToProps, 
    mapDispatchToProps)(LoginPage)