import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { DashAdminPage } from './Admin/DashAdminPage'
import InscripcionAdd from './Inscripciones/InscripcionAdd'
import InscripcionEdit from './Inscripciones/InscripcionEdit'
import { InscripcionesPage } from './Inscripciones/InscripcionesPage'
import { SemestrePage } from './Semestre/SemestrePage'
import PropTypes from 'prop-types';
import NavBar from './Nav/NavBar';
import TopNavBar from './Nav/TopNavBar';

import '../../App.css';
import '../../panel_layout.css';
import { InscripcionDetail } from './Inscripciones/InscripcionDetail'
import AlumnoAddContainer from './Alumno/AlumnoAddContainer'

class LayoutAdmin extends React.Component {

    render(){
      const { match } = this.props
      return(
        <>
            <NavBar />
            <div class="container-fluid ">
                <div class="row vh-100 ">
                    <div id="dashboard" class="bg-secondary vh-100 col-12 nopadding">
                        <TopNavBar></TopNavBar>
                        <div class="dash-content">
				            <div class="row" >
                            <Switch>
                                <Route 
                                    exact path={`${match.path}`}
                                    render={(props) => <DashAdminPage {...props} /> }
                                />
                                <Route path={`${match.path}/alumno/`}      component={AlumnoAddContainer} />
                                <Route path={`${match.path}/semestre/`}      component={SemestrePage} />

                                <Route path={`${match.path}/inscripciones/`}    component={InscripcionesPage} />
                                <Route path={`${match.path}/inscripcion/add`}   component={InscripcionAdd} />
                                <Route path={`${match.path}/inscripcion/edit`}   component={InscripcionEdit} />
                                <Route path={`${match.path}/inscripcion/:id`}      component={InscripcionDetail} />
                                
                                <Route path={`${match.path}/estudiantes`}       component={InscripcionAdd} />                        
                                <Route path={`${match.path}/profesores`}        component={InscripcionAdd} />
                            </Switch>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
                    
           
        </>
      )
   }
 }

 LayoutAdmin.propTypes = {
     match: PropTypes.any.isRequired
 }

export default LayoutAdmin