import React from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants'

export const LinksEstudiante = (props) => {

    return (
        <ul>
            <li><Link to="/panel/">Dashboard</Link></li>
        </ul>
    )
} 
