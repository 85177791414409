import React, { Component } from 'react'
import AlumnoForm from './AlumnoForm'
/*
const AlumnoForm = ({ handleSubmit }) => (
    <form
    onSubmit={handleSubmit}
    >
    <input type="text" name="alumnoName"></input>
    </form>
    );
    
    export default reduxForm({ form: 'alumno'})(AlumnoForm)*/
    
export default class AlumnoAddContainer extends Component {  
    state = { initialValues: null }
    
    mySubmit =  (values) => {
        console.log(values)
    }
    render() {
       

        return (
            <AlumnoForm onSubmit={this.mySubmit}  />
        )
    }
}