import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { DashProfesorPage } from './Admin/DashProfesorPage'
import InscripcionAdd from './Inscripciones/InscripcionAdd'
import InscripcionEdit from './Inscripciones/InscripcionEdit'
import { EstudianteDetail } from './Estudiantes/EstudianteDetail'
import { MateriasPage } from './Materias/MateriasPage'
import  MateriaDetail  from './Materias/MateriaDetail'

import { MensajesPage } from './Mensajes/MensajesPage'
import PropTypes from 'prop-types';
import NavBar from './Nav/NavBar';
import TopNavBar from './Nav/TopNavBar';

import '../../App.css';
import '../../panel_layout.css';

class DashboardProfesorLayout extends React.Component {

    render(){
      const { match } = this.props
      return(
        <>
            <NavBar />
            <div class="container-fluid ">
                <div class="row vh-100 ">
                    <div id="dashboard" class="bg-secondary vh-100 col-12 nopadding">
                        <TopNavBar></TopNavBar>
                        <div class="dash-content">
				            <div class="row" >
                            <Switch>
                                <Route 
                                    exact path={`${match.path}`}
                                    render={(props) => <DashProfesorPage {...props} /> }
                                />
                                <Route path={`${match.path}/materias/`}      component={MateriasPage} />

                                <Route path={`${match.path}/materia/:id`}    component={MateriaDetail} />
                                <Route path={`${match.path}/materia/clase/add`}   component={InscripcionAdd} />
                                <Route path={`${match.path}/materia/clase/edit/:id`}   component={InscripcionEdit} />

                                <Route path={`${match.path}/estudiante/:id`}    component={EstudianteDetail} />
                                
                                <Route path={`${match.path}/mensajes`}       component={MensajesPage} />  
                            </Switch>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
                    
           
        </>
      )
   }
 }

 DashboardProfesorLayout.propTypes = {
     match: PropTypes.any.isRequired
 }

export default DashboardProfesorLayout