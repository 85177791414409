import React from 'react';
import fakedata from '../../../AFakeData/fakedata.json'
import MateriaBox from './MateriaBox'

function listMaterias() {
    //Fetch lista Materias
    //Map with MateriaBox Componet
    //with props?
}

export const MateriasPage = (props) => {
    const numbers = [1,2,3,4,1]
    return (
        <div className="container mgntop">
            <h1>Materias</h1>
            <h3>Selecciona una Materia para comenzar</h3>  
            {listMaterias}
            <div class="row">
                {fakedata.map( materia => {
                    return <MateriaBox key={materia.id} materia={materia} />
                })}
            </div>
        </div>
    )
}