//ACTIONS
//USER
export const LOGIN_USER_ACTION   = 'LOGIN_USER_ACTION';
export const LOGIN_USER_SUCCESS  = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR    = 'LOGIN_USER_ERROR';
export const LOGOUT_USER_ACTION  = 'LOGOUT_USER_ACTION';

//INSCRIPCION
export const INSCRIPCION_ADD            = 'INSCRIPCION_ADD';
export const INSCRIPCION_ADD_ERROR      = 'INSCRIPCION_ADD_ERROR';
export const INSCRIPCION_ADD_SUCCESS    = 'INSCRIPCION_ADD_SUCCESS';
export const INSCRIPCION_GET            = 'INSCRIPCION_GET';
export const INSCRIPCION_UPDATE         = 'INSCRIPCION_UPDATE';
