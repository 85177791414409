import React from 'react';
import { Inscripciones } from './Inscripciones';
import { InscripcionDetail } from './InscripcionDetail';

export const InscripcionesPage = (props) => {
    
    return (
        <div className="container-fluid nopadding">
            <h2>Inscripciones</h2>
            <div className="row">
                <div className="col-12 col-md-8 h-100 bg-secondary bg-success">
                    <Inscripciones /> 
                </div>
                <div className="col-12 col-md-4 bg-warning">
                    <InscripcionDetail />
                </div>

            </div>
        </div>
    )
}