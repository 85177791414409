import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import PropTypes, { string, email } from 'prop-types'
import { connect } from 'react-redux'
import * as Messages from '../../../Helpers/Messages'

//Actions Redux
import { crearInscripcion } from '../../../Actions/InscripcionesActions'

const validation = ({nameField='', emailField=''}) => {
    let error = {}
    let isError = false
    if (nameField.trim() === ''){
        error.nameField ='Required'
        isError = true;
    }
    if (nameField.length > 20){
        error.nameField ='Too long'
        isError = true;
    }
    if (emailField.trim() === ''){
        error.emailField ='Required'
        isError = true;
    }
    
    if (isError){
        throw new SubmissionError(error)
    } else {
        console.log('Valid submited form')
    }
    //console.log(nameField)
}

const isRequiredF = (value) => (
    !value && Messages.Validation__Required
);
const isNumber =  (value) => (
    isNaN(Number(value)) && Messages.Validation__Number
)

const renderField = ({ label, input, type="text", placeholder, meta: {touched, error}}) => (
    <div className="">
        <label>{label}</label>
        <input {...input} className="form-control" type={type} placeholder={placeholder} />
        {touched && error &&
            <span className="error">{error}</span>
        }
    </div>
)

//la validacion funciona con validacion individual de campos
//o con funcion handleSumbit(), actualmente viene funcion como parametro
//desde el componente padre (InscricionAdd)
const AddFormFunc = ({handleSubmit, submitting}) => (
            <form id="InscripcionAdd" action="" 
            //onSubmit={handleSubmit()}            
            onSubmit={handleSubmit}
            >
                <div className="form-group">
                    <Field  
                        name="nameField"      
                        component={renderField}
                        placeholder="Name"
                        label="Name"
                        validate={[isNumber, isRequiredF]}
                         ></Field>
                  <Field 
                        name="emailField"    
                        component={renderField}
                        type="email"
                        placeholder="Email"
                        validate={isRequiredF}
                        label="Email"></Field>
                    <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary">
                        Agregar
                    </button>
                </div>
            </form>

)
AddFormFunc.propTypes={
    nameField: PropTypes.string,
    emailField: PropTypes.string
}

/*
AddFormFunc.prototype = {
    handleSubmit: PropTypes.func.isRequired
}*/

const InscripcionAddForm = reduxForm({ 
    form: 'AddFormFunc',
    validation
})(AddFormFunc)

export default connect(
    (state, props) => ({ initialValues: props }))(InscripcionAddForm)

/*
const AddFormFuncConnect = connect(
    state, props,
) => ({ initialValues: props })
(reduxForm({
    form: 'AddFormFunc',
    validation,
    onSubmit: values => values
})(AddFormFunc))
export default AddFormFuncConnect*/

/*
export default reduxForm({ 
    form: 'AddFormFunc',
    validation
})(AddFormFunc);*/