import React from 'react';

export const DashProfesorPage = (props) => {

    return (
        <div className="container mgntop">
            <h2>Profesor Dashboard</h2> 
                    
        </div>
    )
}