import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TopNavBar extends Component {
    
  signOut = (e) => {
    e.preventDefault()
    
  }
    render () {
        return(
            
        <nav className="mb-1 navbar navbar-expand-lg navbar-dark bg-primary lighten-1">
          <a className="navbar-brand" href="#">Navbar</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-555"
            aria-controls="navbarSupportedContent-555" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-555">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/panel/mensajes" className="nav-link">Mensajes</Link>                
              </li>               
            </ul>
            <ul className="navbar-nav ml-auto nav-flex-icons">
              <li className="nav-item">
                <a className="nav-link waves-effect waves-light">1
                  <i className="fas fa-envelope"></i>
                </a>
              </li>
              <li className="nav-item avatar dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-55" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" className="rounded-circle z-depth-0"
                    alt="avatar image" />
                </a>
                <div className="dropdown-menu dropdown-menu-lg-right dropdown-secondary"
                  aria-labelledby="navbarDropdownMenuLink-55">
                  <a className="dropdown-item" href="/logout">Cerrar Sesión</a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        )
    }
}

export default TopNavBar;