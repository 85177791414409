import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as actions from '../../Actions/UserActions';
import { history } from '../../Helpers/History'
import { connect } from 'react-redux'


class AreaLogin extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            username    : "",
            password    : "",
            isProcessing: false,
        }

        this.handleSubmit   = this.handleSubmit.bind(this);
        this.handleChange   = this.handleChange.bind(this);
    }

    handleChange (e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit (e) {
        e.preventDefault(); 

        this.setState({ isProcessing: true }); //esta funcionando?
        
        //DISPATCH HERE
        //enviando accion como props, junto con el state actualizado
        this.props.tryLoginUserAction(this.state).then(
            (response) => {
                if(response.success == true) {
                    history.push("/panel") 
                } else{
                    console.log("revisar")
                }
            },
            (err) => this.setState({errors: err.data.error, isProcessing: false})
        )
        //const { username, password } = this.state*/
                
    }

    render () {
        return(
            <div className="idv-box position-absolute bg-white text-dark text-center">
                <img className="idv-logo d-inline text-center" 
                alt="Instituto de Diseño de Valencia" 
                src="img/instituto-de-diseno-de-valencia.svg"  />
                <h1>Te damos la Bienvenida</h1>
                <div>Ingresa tu usuario o email</div>
                status {this.props.userReducer.loggedStatus}

				<form onSubmit={this.handleSubmit}>

                    <input 
                        type="text"
                        name="username" 
                        id="login-input-username" 
                        className="form-control" 
                        onChange= { this.handleChange }
                        placeholder="Usuario o Email" 
                        required="required"
                        ref={inputElement => this.inputUsername = inputElement} 
                        value={this.state.username}
                        />
                    <input 
                        type="text"
                        name="password" 
                        id="login-input-password" 
                        className="form-control" 
                        onChange= { this.handleChange }
                        placeholder="Contraseña" 
                        required="required"
                        ref={inputElement => this.inputPassword = inputElement} 
                        value={this.state.password}
                        />

                    <input 
                        type="submit" 
                        className="btn btn-primary"     
                        value="Ingresar"
                        disabled={this.state.isProcessing} />
				</form>
                <Link to="/about">About</Link>
                <a className="btn" href="#" role="button">Olvidé mi contraseña</a>
				<a className="btn" href="#" role="button">aún no tengo usuario</a>					
            </div>
            
        )
    }
}


const mapStateToProps = (state) => {
    return { 
      userReducer: state.userReducer,
    }
}

const mapDispatchToProps = dispatch => ({
    tryLoginUserAction : (dataFields) => dispatch(actions.tryLoginUserAction(dataFields))
})
  
 export default connect(mapStateToProps, mapDispatchToProps)(AreaLogin)