import React, { Component } from 'react';
import fakedata from '../../../AFakeData/fakedata.json'



class MateriaDetail extends Component {
    render(){
        return (
            <div className="container mgntop">
                <h1>Materia ID</h1>
                
            </div>
        )
    }
}

export default MateriaDetail