import React, { Component } from 'react';
//import { createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux'

import { BrowserRouter, Switch, Route, StaticRouter } from 'react-router-dom';

import * as Constants from './Constants/Constants';

import LoginPage  from './Pages/LoginPage/LoginPage';
import LayoutAdmin from './Pages/Dashboard/LayoutAdmin';
import LayoutProfesor from './Pages/Dashboard/LayoutProfesor';
import LayoutEstudiante from './Pages/Dashboard/LayoutEstudiante';
import ProfileLayout from './Pages/Dashboard/Profile/ProfileLayout';
import SignOut from './Pages/Dashboard/Profile/SignOut';
import { NotFound } from './Pages/NotFound';

import { history } from './Helpers/History'

//Actions
import { getProfileFetch } from './Actions/UserActions';
//import ProtectedRoute from './components/ProtectedRoute'


import './App.css';
import './css.css';

class App extends Component {

  constructor() {
    super();

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  
 
  handleLogout() {
    this.setState({
      loggedStatus: "NOT_LOGGED_IN",
      user: {}
    });
  }
  handleLogin(data) {
    this.setState({
      loggedStatus: "LOGGED_IN",
    });
  }


  render() {
    //console.log(this.props);
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route 
              exact path="/" 
              render= { props => (
                <LoginPage
                  {...this.props} //props
                  handleLogin     = {this.handleLogin}
                  handleLogout    = {this.handleLogout}
                  //loggedInStatus  = {this.state.loggedInStatus}                  
                  loggedStatus  = { this.props.loggedStatus }
                />
              )}
              />
              <Route path='/profile' render={(props) => <ProfileLayout {...props} /> } />
              <Route path='/panel' render={ (props) => { 
                  
                  switch (localStorage.getItem(Constants.TOKEN_SESSION_ROLE)) {
                    case 'admin':
                      return <LayoutAdmin {...props} /> ;                   
                    case 'teacher':
                      return <LayoutProfesor {...props} />;
                    default:
                      return <LayoutEstudiante {...props} />;
                  }
                }
              }/>


            <Route path='/logout' render={(props) => <SignOut {...props} /> } />
            <Route component ={NotFound}  />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    estudianteReducer: state.estudianteReducer,
    userReducer: state.userReducer
  }
}

const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => dispatch(getProfileFetch())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)