import React, { Component } from 'react';
import { reduxForm, Field, getFormInitialValues } from 'redux-form';
import AddForm from './AddForm';
import * as types from '../../../Actions/ActionTypes';
import { connect, useDispatch, useSelector } from 'react-redux';

import {addInscripcion} from '../../../Actions/InscripcionesActions'

import { } from '../../../Actions/InscripcionesActions'

class InscripcionAdd extends Component {

    //Funcion personalizada que pasa al componente de ReduxForm
    //desde aqui se inicia la logica de axios
    
    handleSubmitInscripcion = (values) => {
        //Logic here
        console.log(values)
    }
    
    render() {  
        /*
        this.state = {
            nameField: '7',
            emailField: 'me@me.com',
            isSubmitting: false
        }*/
        return (
            <div className="col-8">
                <h1>Agregar Inscripcion</h1>
                <form className="">
                    <div className="form-group">
                        <AddForm 
                            {...this.state}
                            onSubmit={this.handleSubmitInscripcion}
                        ></AddForm>
                    </div>
                </form>
            </div>
        )
    }
}

/*
const mapDispatchToProps = (dispatch) => {
    return {
        handleSubmit: () => dispatch ( addInscripcion())    
    }
}
*/


const mapDispatchToProps = (dispatch) => {
    console.log ('in mapDispatchToProps')
    return {
        /*handleSubmity: value => dispatch({ 
            type: types.INSCRIPCION_ADD, 
            payload: value 
        }*/
      //  alert: () => { dispatch( ALERT_ACTION ) }
    //)
    }
};
const mapStateToProps = (state, props) => {
    console.log(state.userReducer)
    
}
/*
const mapStateToProps = (state, props) => ({
   // inscripcion: getInscripcionByID(state, props)
});*/
/*
const mapDispatchToProps = (dispatch) => ({
    onSubmit: (fields) =>
    dispatch({
        type: types.INSCRIPCION_ADD,
         payload: fields
        })
});*/


export default connect(
    mapStateToProps, 
    mapDispatchToProps)(InscripcionAdd);