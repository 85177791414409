import * as types from '../Actions/ActionTypes'
import { userActions } from '../Actions/InscripcionesActions';

const inscripcionesReducer = (state = [], action) => {
    switch(action.type){
        case 'INSCRIPCION_ADD':   //START_INSCRIPCION_ADD 
            console.log (action.payload) 
            console.log ("from Reducer")        
            return {...state, 
                userdata: action.payload,
                isSubmitting: true
            }            
        /*case 'INSCRIPCION_ADD':           
            return {...state, 
                userdata: action.payload,
                isSubmitting: false
            }*/
        default: return state
    }
}

export default inscripcionesReducer


            
          
            /*return {...state,
                    currentUser: action.payload,
                    loggedIn: true,
                    user: {...action.payload}
                }*/