import React, { Component } from 'react';
//import MetaTags from 'react-meta-tags';

//import './App.css';
export class HeaderExternal extends Component {
    
    render () {
        return(
            
        <h1>Dashboard {this.props.title}</h1>
        )
    }
}