import React, { Component } from 'react';

import { HeaderExternal } from '../default/HeaderExternal';

export class DashboardEstudiante extends Component {
    
    render () {
        return(
            <HeaderExternal title={"Estudiante"} />
            
        )
    }
}

export default DashboardEstudiante;
