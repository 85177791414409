import React from 'react';
import AddForm from './AddForm';

export const InscripcionDetail = (props) => {
    
    return (
        <>
            <h2>Inscripcion Detalle</h2>
          
        </>
    )
}