import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ProfilePage } from './ProfilePage'
import { PageA } from './PageA'
import { PageF } from './PageF'
import PropTypes from 'prop-types';

class LeftNavLayout extends React.Component {

    render(){
      const { match } = this.props
      return(
        <>
            <div className="row">
                <div className="leftnav col-md-3">
                    <div className="link row">
                        <a href="/profile/paged">Page A</a>
                    </div>                    
                    <div className="link row">
                        <a href="/profile/pagef">Page F</a>
                    </div>
                    <div className="row">
                        
                    </div>
                </div>
                <div className="col-md-9">
                    <Switch>
                        <Route 
                            exact path={`${match.path}`}
                            render={(props) => <ProfilePage {...props} /> }
                        />
                        <Route path={`${match.path}/paged`} component={PageA} />
                        <Route path={`${match.path}/pagef`} component={PageF} />
                    </Switch>
                </div>
            </div>
        </>
      )
   }
 }

 LeftNavLayout.propTypes = {
     match: PropTypes.any.isRequired
 }

export default LeftNavLayout