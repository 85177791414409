import React from 'react';
import { Link } from 'react-router-dom';

export const MateriaBox = (props) => {

    const { materia } = props
    return (
        <div class="col-sm-4">
            <Link to={`/panel/materia/${materia.id}`}>
                <div className="card">
                    <div class="card-title">{materia.title}</div>
                    <>{materia.especialidad}</>
                    <small>Lun 7am</small>
                </div>
                <br></br>
            </Link>
        </div>
    )
}

export default MateriaBox