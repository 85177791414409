import React from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../Constants/Constants'

export const LinksAdmin = (props) => {

    return (
        <ul>
            <li><Link to="/panel/">Dashboard</Link></li>
            <li><Link to="/panel/semestre">Semestre</Link></li>
            <li><Link to={Constants.Link_inscripciones__main}>Inscripciones</Link></li>
            <li><Link to="/panel/profesores">Profesores</Link></li>
            <li><Link to="/panel/estudiantes">Estudiantes</Link></li>
        </ul>
    )
} 
