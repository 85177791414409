import React, { Component } from 'react';
import { InscripcionesPage } from './InscripcionesPage';
import { reduxForm, Field, getFormInitialValues } from 'redux-form';
import AddForm from './AddForm';
import { connect, useDispatch, useSelector } from 'react-redux';

import { } from '../../../Actions/InscripcionesActions'

class  InscripcionEdit extends Component {

    componentDidMount(){
        if (!this.props.inscripcion){
           //this.props.getInscripcion()
        }
    }
    
    handleSubmit = (values) =>{
        console.log("submited");
        console.log(values);
        //const { id } = values
        //this.props.updateInscripcion(id,values)

    }

    getInitialValues() {
        return {
            nameField: 'rajat',
            emailField: '@',
        };
    }
    //console.log(initialValues)
    render() {
        //InitialValue
        this.state = {
            nameField: 'holis',
            emailField: 'me@me.com',
            isSubmitting: false
        }

        return (
            <div className="col-8">
            <h1>Agregar Inscripcion</h1>
            <form className="">
                <div className="form-group">
                    <AddForm 
                        {...this.state}
                        //{...this.props}
                        onSubmit={this.handleSubmit}
                        //initialValues={getInitialValues()}
                        //initialValues={this.props.state.inscripcion}
                    ></AddForm>
                </div>
            </form>
            </div>
        )
    }
}
const mapStateToProps = (state, props) => ({
   // inscripcion: getInscripcionByID(state, props)
});

export default connect(mapStateToProps, {
    //getInscripcion()
})(InscripcionEdit);

