import * as actions from './ActionTypes';
import * as Constants from '../Constants/Constants';
import axios from 'axios';
//import { useHistory } from "react-router-dom";
import { browserHistory } from 'react-router'
import { history } from '../Helpers/History';


export function LoginUserAction(userObj) {
    console.log(userObj);
   /* return { 
        type: actions.LOGIN_USER_ACTION, 
        payload: {
            userObj,
            isProcessing: true
        },
    };*/
}



//Actions

export function logout(userInfo) {
    //userService.logout();
    return { 
        type: actions.LOGOUT_USER_ACTION, 
        userInfo 
    };
}

export const getProfileFetch = () => {
    return (dispatch) => {
        const token = localStorage.getItem(Constants.TOKEN_SESSION);
        console.log(!!token)
        /*if (!!token) {
            axios
                .get(Constants.ApiURL+'/checkuser')
                .then(response => {
                    //console.log(response.data.success);
                    //if token invalid
                    if (response.data.success != true){
                        localStorage.removeItem("token")
                        return false
                    } else {
                        return true //response.data.success //que retorno?
                    }
                    return response.data.success
                }, error => {
                    console.log(error);
                });            
        } else {
            return false
        }*/
        if (!!token) {
            //return true
            return axios
                .post(
                    Constants.ApiURL+"/checkUser",
                )
                .then(
                    response => {
                        console.log(response)
                        if (response.data.success == true){
                            
                        } else {
                            localStorage.removeItem(Constants.TOKEN_SESSION)
                            
                        }
                        
                    }
                )
                .catch(error => {
                    //this.setState({ errorMessage: error.message });
                    console.log("login error", error);
            });
        } 
        



    }
}

export const tryLoginUserAction = (dataFields) => {
    return dispatch => {
        return axios
            .post(
                Constants.ApiURL+"/dummy",               
                { 
                    data:{ 
                        username:dataFields.username, 
                        password:dataFields.password
                     }
                }, {withCredentials:true}
            )
            .then(
                response => {
                    console.log(response)
                    if (response.data.success == true){
                        localStorage.setItem(Constants.TOKEN_SESSION, response.data.token) //add expiration
                        localStorage.setItem(Constants.TOKEN_SESSION_USER, dataFields.username)
                        localStorage.setItem(Constants.TOKEN_SESSION_ROLE, response.data.role)
                    }
                    return response.data
                    
                }
            )
            .catch(error => {
                //this.setState({ errorMessage: error.message });
                console.log("login error", error);
        });
    }
}