import userReducer from './UserReducer'
import estudianteReducer from './EstudianteReducer'
import inscripcionReducer from './InscripcionReducer'
import alumnoReducer from '../Pages/Dashboard/Alumno/AlumnoReducer'
import { combineReducers } from 'redux'
import { reducer as reduxForm } from 'redux-form'

const rootReducer = combineReducers({
    userReducer,
    estudianteReducer,
    inscripcionReducer,
    alumnoReducer,
    form: reduxForm
});

export default rootReducer;