import * as actions from '../Actions/ActionTypes'
import { userActions } from '../Actions/UserActions';


const initialState = {
    currentUser: {
        username    : "",
        password    : ""  
    },
    isProcessing: false,
    loggedIn:   false,
 };
  
export default function userReducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER_ACTION:
            //logic + return
           // const token = localStorage.token;
            console.log (action.payload)
            
           //history.push('/')
            return {...state,
                    currentUser: action.payload,
                    loggedIn: true,
                    isProcessing: true
                    //user: {...action.payload}
                }
        case actions.LOGIN_USER_SUCCESS:           
                return state
        default:
            return state;
    }
}
