import * as types from './ActionTypes';
import * as Constants from '../Constants/Constants';
import clientAxios from './clientAxios';
import axios from 'axios';

export function crearInscripcion(data){

    return () => {
        console.log('1- Crear desde ACTIONjs')
    }
    /*return async (dispatch) => {
        console.log('1- Crear desde ACTIONjs')
        dispatch(addInscripcion());
        //temp, borrar esto
        const inscripcion = ""
        try{
            //axios Insert (hacia Api)
            await clientAxios.post('/inscripcion', inscripcion);
            //si todo ok, actualizar State (hacia State)
            dispatch(addInscripcionSucess(inscripcion))
        } catch (error){
            //si hay error, cambiar state
            dispatch(addInscripcionError(true))
        }
    }*/
}

export const addInscripcion = (inscripcion) => ({
    type: types.INSCRIPCION_ADD,
    payload: true
})
// si la inscripcion se guarda
const addInscripcionSucess = (inscripcion) => ({
    type: types.INSCRIPCION_ADD_SUCCESS,
    payload: inscripcion
})
// si hubo un error
const addInscripcionError = (estado) => ({
    type: types.INSCRIPCION_ADD_ERROR,
    payload: estado
})

const getInscripion = (id) => ({
    type: types.INSCRIPCION_GET,
    payload: id
})


//export const updateInscripcion = createAction (types.INSCRIPCION_UPDATE);